import React from "react";
import { EyeOutlined } from "@ant-design/icons";

import flyer1 from "../../../../../assets/flyer1.jpeg";
import flyer2 from "../../../../../assets/flyer2.jpeg";
import flyer3 from "../../../../../assets/flyer3.jpg";
import flyer4 from "../../../../../assets/flyer4.jpg";

const Flyer = () => {
  return (
    <div>
      {/*  Flyers  */}
      <div className='flyer-container'>
        <a href={flyer3} target='_blank' className='flyer-img-container'>
          <img src={flyer3} alt='flyer3' className='flyer' />
        </a>
        <a href={flyer4} target='_blank' className='flyer-img-container'>
          <img src={flyer4} alt='flyer4' className='flyer' />
        </a>
      </div>

      <div className='flyer-container'>
        <a href={flyer1} target='_blank' className='flyer-img-container'>
          <img src={flyer1} alt='flyer1' className='flyer' />
        </a>
        <a href={flyer2} target='_blank' className='flyer-img-container'>
          <img src={flyer2} alt='flyer2' className='flyer' />
        </a>
      </div>
    </div>
  );
};

export default Flyer;
