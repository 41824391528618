import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { ImportOutlined } from "@ant-design/icons";
import { signout } from "../../../../helpers/auth";
import SahiBukhari from './SahiBukhari';
import TafseerQuran from './TafseerQuran';
import UploadLecture from "./UploadLecture";

const Admin = ({ history }) => {
    const [name, setName] = useState('');

    // Signout
    const handleSignOut = () => {
        signout(() => {
            history.push("/");
        });
    };
    const handleClick = (names) => {
        setName(names);
    };
    return (
        <div className='container'>
            <div className='admin-home-container'>
                <div className='admin-nav'>
                    <div>
                        <div className='admin-title'>Sakeenat Institute Lectures</div>
                    </div>
                    <button className='main-color-scheme admin-btn' onClick={handleSignOut}>
                        <ImportOutlined /> Sign Out
                    </button>
                </div>
                <br></br>
                <div className='admin-body-container'>
                    <button className='main-color-scheme admin-btn' style={{ marginRight: '0.5rem' }} onClick={() => handleClick('Tafseer')}>
                        Tafseer-Quran-Class
                    </button>
                    <button className='main-color-scheme admin-btn' onClick={() => handleClick('Sahi')}>
                        Sahi-Bukhari-Class
                    </button>
                </div>
                <UploadLecture />
                {name == "Tafseer" && <TafseerQuran />}
                {name == "Sahi" && <SahiBukhari />}
            </div>
        </div>
    );
};

export default withRouter(Admin);

/* <audio controls>
<source src={lecture3} type='audio/mpeg' />
Your browser does not support the audio element.
</audio> */
