import React, { useState } from "react";
import AWS from 'aws-sdk';
import axios from 'axios';
import { notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';

const UploadLecture = () => {
    const [files, setFiles] = useState([]); // Array to handle multiple lecture files
    const [name, setName] = useState(''); // Lecture name/title
    const [date, setDate] = useState(''); // Date of the lecture
    const [type, setType] = useState(''); // Lecture type
    const [uploading, setUploading] = useState(false); // Uploading state
    const [inputsLocked, setInputsLocked] = useState(false); // State to lock inputs

    // Configure Wasabi S3
    const wasabiEndpoint = new AWS.Endpoint('s3.wasabisys.com'); // Use region-specific if needed
    const s3 = new AWS.S3({
        endpoint: wasabiEndpoint,
        region: 'us-east-1', // Ensure this matches your Wasabi bucket's region
        accessKeyId: 'I8ITVDL3GT2TQA0ZFSAB', // Replace with your actual Wasabi Access Key ID
        secretAccessKey: 'DszVpDdzqP1si8PUybJQ3ae3784AJ1vTSN2sqRTq', // Replace with your actual Wasabi Secret Access Key
        s3ForcePathStyle: true // Required for Wasabi to ensure correct URL path formatting
    });

    // Handlers for input changes with sequential validation
    const handleTypeChange = (e) => {
        if (type) return;
        setType(e.target.value);
    };

    const handleDateChange = (e) => {
        if (!type) {
            notification.error({ message: "Please select the lecture type first." });
            return;
        }
        setDate(e.target.value);
    };

    const handleNameChange = (e) => {
        if (!date) {
            notification.error({ message: "Please enter the date first." });
            return;
        }
        setName(e.target.value);
    };

    const handleFileChange = (e) => {
        e.persist();
        if (!name) {
            notification.error({ message: "Please enter the lecture name/title first." });
            return;
        }
        const newFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setInputsLocked(true); // Lock other inputs after file selection
    };

    const handleUpload = async () => {
        setUploading(true);
        try {
            for (const file of files) {
                const fileKey = `${Date.now()}-${file.name}`; // Generate a unique file key

                const presignedUrl = s3.getSignedUrl('putObject', {
                    Bucket: 'sakeenat', // Replace with your Wasabi bucket name
                    Key: fileKey,
                    ContentType: file.type,
                    Expires: 60
                });

                const response = await fetch(presignedUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': file.type,
                    },
                    body: file,
                });

                if (response.ok) {
                    notification.success({
                        message: 'File uploaded successfully!',
                        description: `File ${file.name} has been uploaded.`,
                    });
                } else {
                    notification.error({
                        message: 'Upload Failed',
                        description: `Failed to upload file: ${response.statusText}`,
                    });
                }
            }
        } catch (error) {
            console.error('Error during file upload:', error);
            notification.error({
                message: 'Upload Error',
                description: error.message,
            });
        } finally {
            setUploading(false);
            setFiles([]);
            setName('');
            setDate('');
            setType('');
            setInputsLocked(false);
        }
    };

    return (
        <div>
            <h2>Upload a New Lecture</h2>
            <form className="upload-form">
                <div>
                    <label>Lecture Type:</label>
                    <select value={type} onChange={handleTypeChange} disabled={inputsLocked}>
                        <option value="">Select Type</option>
                        <option value="Tafseer ul Quran class">Tafseer ul Quran class</option>
                        <option value="Sahi Bukhari Class">Sahi Bukhari Class</option>
                    </select>
                </div>
                <div>
                    <label>Date:</label>
                    <input
                        type="text"
                        value={date}
                        onChange={handleDateChange}
                        placeholder="Date (e.g., January 9th 2020)"
                        disabled={inputsLocked}
                    />
                </div>
                <div>
                    <label>Lecture Name/Title:</label>
                    <input
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                        placeholder='Lecture title (e.g., #0010 - "SURAH AL BAQARAH" Ayat # 30-39 By: Aalima Nikhat Haider Aapa)'
                        disabled={inputsLocked}
                    />
                </div>
                <div>
                    <label>Upload MP4/MP3 Files:</label>
                    <input type="file" accept=".mp4, .mp3" multiple onChange={handleFileChange} />
                </div>
            </form>

            {/* Preview Section */}
            {type && date && name && files.length > 0 && (
                <div className="lecture-preview">
                    <h3>Lecture Preview</h3>
                    <p><strong>Type:</strong> {type}</p>
                    <p><strong>Date:</strong> {date}</p>
                    <p><strong>Title:</strong> {name}</p>
                    <ul>
                        {files.map((file, index) => (
                            <li key={`${file.name}-${index}`}>
                                <p>Lecture {index + 1}: {file.name}</p>
                                <p><strong>File Size:</strong> {(file.size / (1024 * 1024)).toFixed(2)} MB</p>
                                <p><strong>Last Modified:</strong> {new Date(file.lastModified).toLocaleString()}</p>
                            </li>
                        ))}
                    </ul>
                    <button onClick={handleUpload} disabled={uploading}>
                        {uploading ? 'Uploading...' : 'Upload Lecture'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default UploadLecture;
